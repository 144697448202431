<template>
  <div class="content-layout">
    <div class="property-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:product_service:create'])"
          type="primary"
          class="add-btn"
          @click="handleCreator()"
          >新增</a-button
        >
      </div>
      <div class="data-filter">
        <a-input
          clearable
          placeholder="请输入标识符或备注内容"
          v-model="requestParams.searchKey"
          class="search-keyword"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.productService.columns"
      :data-source="tableDate"
      class="property-table"
      rowKey="id"
    >
      <template slot="description" slot-scope="scope">{{
        scope.defaultMessage ? scope.defaultMessage : '暂无'
      }}</template>
      <template slot="timeout" slot-scope="scope">{{ scope ? `${scope}ms` : '暂无' }}</template>
      <span slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:product_service:update'])"
          class="green-btn"
          ghost
          @click="jumptoEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button
          v-if="$hasPermissions(['rcs:product_service:get'])"
          class="green-btn"
          ghost
          @click="jumptoDetail(scope)"
          >详情</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:product_service:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除产品服务?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductServiceManagement',
  components: {},
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listProductService() {
      const params = {
        searchKey: this.requestParams.searchKey,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.productService.getProductService(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      await this.listProductService();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listProductService();
      }
    },
    handleCreator() {
      this.$router.push({ name: 'productServiceCreator' });
    },
    jumptoEdit(record) {
      this.$router.push({ name: 'productServiceEditor', params: { id: record.id } });
    },
    jumptoDetail(record) {
      this.$router.push({ name: 'productServiceDetail', params: { id: record.id } });
    },
    onDelete(record) {
      this.$apiManager.productService.deleteProductService(record.id).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.property-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .property-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
